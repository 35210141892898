import React from 'react';
import LogoImage from 'images/logo_hrbasepro.png';

export const Logo: React.FC = () => (
  <div className="flex-shrink-0">
    <a href="/consultants">
      <img
        className="h-10 w-auto"
        src={LogoImage}
        alt="logo"
      />
    </a>
  </div>
);
