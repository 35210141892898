/* eslint-disable react/destructuring-assignment */
/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import {
  HomeIcon, UserGroupIcon, ChatAlt2Icon, DatabaseIcon,
} from '@heroicons/react/solid';
import { Logo } from '../components/logo';
import {
  User, Navigation, UserNavigation, HeaderProps,
} from '../types/header';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Header: React.FC<HeaderProps> = (props) => (
  <div className="fixed z-40 w-full">
    <Disclosure as="nav" className="bg-header">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex items-center justify-between h-16">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <Logo />
                </div>
                <div className="hidden md:block">
                  <div className="ml-10 flex items-baseline space-x-4">
                    {props.navigation.map((item) => (item.current ? (
                      <Fragment key={item.name}>
                        <a
                          href={item.href}
                          className="
                            bg-headerActive
                            text-white
                            px-3 py-2
                            rounded-md
                            text-sm
                            font-medium
                        "
                        >
                          <item.icon className="float-left h-5 w-5 pr-1" aria-hidden="true" />
                          {item.name}
                        </a>
                      </Fragment>
                    ) : (
                      <a
                        key={item.name}
                        href={item.href}
                        className="text-white hover:bg-headerActive hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                      >
                        <item.icon className="float-left h-5 w-5 pr-1" aria-hidden="true" />
                        {item.name}
                      </a>
                    )))}
                  </div>
                </div>
              </div>
              <div className="hidden md:block">
                <div className="ml-4 flex items-center md:ml-6">
                  {/* Profile dropdown */}
                  <Menu as="div" className="ml-3 relative">
                    {({ open }) => ( // eslint-disable-line
                      <>
                        <div>
                          <Menu.Button className="
                            max-w-xs bg-gray-800
                            rounded-full flex
                            items-center text-sm
                            focus:outline-none focus:ring-2
                            focus:ring-offset-2
                            focus:ring-offset-gray-800 focus:ring-white
                          "
                          >
                            <span className="sr-only">Open user menu</span>
                            <span className="inline-block h-8 w-8 rounded-full overflow-hidden bg-gray-100">
                              <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                                <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                              </svg>
                            </span>
                          </Menu.Button>
                        </div>
                        <Transition
                          show={open}
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items
                            static
                            className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                          >
                            {props.userNavigation.map((item) => (
                              <Menu.Item key={item.name}>
                                {({ active }) => (
                                  <a
                                    href={item.href}
                                    data-method={(item.method !== null) ? item.method : ''}
                                    className={classNames(
                                      active ? 'bg-gray-100' : '',
                                      'block px-4 py-2 text-sm text-gray-700',
                                    )}
                                  >
                                    {item.name}
                                  </a>
                                )}
                              </Menu.Item>
                            ))}
                          </Menu.Items>
                        </Transition>
                      </>
                    )}
                  </Menu>
                </div>
              </div>
              <div className="-mr-2 flex md:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="
                  bg-gray-800 inline-flex items-center
                  justify-center p-2 rounded-md text-gray-400
                  hover:text-white hover:bg-gray-700 focus:outline-none
                  focus:ring-2 focus:ring-offset-2
                  focus:ring-offset-gray-800 focus:ring-white
                "
                >
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="md:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
              {props.navigation.map((item, itemIdx) => (itemIdx === 0 ? (
                <Fragment key={item.name}>
                  <a href={item.href} className="bg-headerActive text-white block px-3 py-2 rounded-md text-base font-medium">
                    {item.name}
                  </a>
                </Fragment>
              ) : (
                <a
                  key={item.name}
                  href={item.href}
                  className="text-white hover:bg-headerActive hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                >
                  {item.name}
                </a>
              )))}
            </div>
            <div className="pt-4 pb-3 border-t border-gray-700">
              <div className="flex items-center px-5">
                <div className="flex-shrink-0">
                  <span className="inline-block h-8 w-8 rounded-full overflow-hidden bg-gray-100">
                    <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                      <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                    </svg>
                  </span>
                </div>
                <div className="ml-3">
                  <div className="text-base font-medium leading-none text-white">{props.user.name}</div>
                  <div className="text-sm font-medium leading-none text-gray-400">{props.user.email}</div>
                </div>
              </div>
              <div className="mt-3 px-2 space-y-1">
                {props.userNavigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    data-method={(item.method !== null) ? item.method : ''}
                    className="block px-3 py-2 rounded-md text-base font-medium text-white hover:text-white hover:bg-headerActive"
                  >
                    {item.name}
                  </a>
                ))}
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  </div>
);

const App = ({ navigation, userNavigation, user }) => (
  <Header
    userNavigation={userNavigation}
    navigation={navigation}
    user={user}
  />
);

$(() => {
  const elm = document.querySelector('#Header');

  const user: User = {
    name: elm.getAttribute('name'),
    email: elm.getAttribute('email'),
    type: elm.getAttribute('type'),
    companyId: elm.getAttribute('company_id'),
    activeLink: elm.getAttribute('active_link'),
  };

  if (user.type === 'consultant_user') {
    const navigation: Array<Navigation> = [
      {
        name: 'トップ', href: '/consultants', current: user.activeLink === 'top', icon: HomeIcon,
      },
      {
        name: '顧客管理', href: '/consultants/companies', current: user.activeLink === 'companies', icon: UserGroupIcon,
      },
      {
        name: '労務相談', href: '/consultants/labor_consultations', current: user.activeLink === 'labor_consultations', icon: ChatAlt2Icon,
      },
      {
        name: '情報共有', href: '/consultants/sharing_templates', current: user.activeLink === 'sharings', icon: DatabaseIcon,
      },
    ];

    const userNavigation: Array<UserNavigation> = [
      { name: '自社情報', href: `/consultants/consultant_companies/${user.companyId}`, method: null },
      { name: 'プラン変更', href: '/consultants/consultant_plans', method: null },
      { name: 'メールアドレス変更', href: '/consultant_users/edit', method: null },
      { name: 'パスワード変更', href: '/consultant_users/change_password/edit', method: null },
      { name: 'ログアウト', href: '/consultant_users/sign_out', method: 'delete' },
    ];
    ReactDOM.render(
      <App navigation={navigation} userNavigation={userNavigation} user={user} />,
      elm,
    );
  } else if (user.type === 'invited_user') {
    const navigation: Array<Navigation> = [
      {
        name: 'トップ', href: '/', current: user.activeLink === 'top', icon: HomeIcon,
      },
      {
        name: '労務相談', href: '/invited_users/labor_consultations', current: user.activeLink === 'labor_consultations', icon: ChatAlt2Icon,
      },
      {
        name: '情報共有', href: '/invited_users/sharings', current: user.activeLink === 'sharings', icon: DatabaseIcon,
      },
    ];

    const userNavigation: Array<UserNavigation> = [
      { name: '自社情報', href: `/invited_users/companies/${user.companyId}`, method: null },
      { name: 'メールアドレス変更', href: '/users/edit', method: null },
      { name: 'パスワード変更', href: '/users/change_password/edit', method: null },
      { name: 'ログアウト', href: '/users/sign_out', method: 'delete' },
    ];
    ReactDOM.render(
      <App navigation={navigation} userNavigation={userNavigation} user={user} />,
      elm,
    );
  }
});
